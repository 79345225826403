.amplify-tabs {
  display: none !important;
}

div[data-amplify-container] {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}
